import { ApolloClient, InMemoryCache } from '@apollo/client'

export const cache = new InMemoryCache()

export const client = new ApolloClient({
  uri: 'https://portal.synxmedia.com/graphql',
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_API_TOKEN}`,
  },
})

export const swrFetcher = <Q = any, V = any>(query: any, variables?: any) => {
  return client.query<Q, V>({
    query,
    variables,
    fetchPolicy: 'network-only',
  })
}

export const swrMutation = <M = any, V = any>(
  mutation: any,
  variables?: any
) => {
  return client.mutate<M, V>({
    mutation,
    variables,
    fetchPolicy: 'network-only',
  })
}
