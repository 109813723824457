export const CMS_ASSETS_PATH = 'https://admin.mrbikersaigon.com/assets'
export const CMS_PATH = 'https://portal.rezolabs.com'
export const SWR_REQUEST_INTERVAL = 2500
export const FALLBACK_IMAGE_PATH = '/og-image.jpeg'
export const CUSTOM_BLOCK_TAG = 'custom-id'
export const CUSTOM_BLOCK_ID = {
  GALLERY_STORY: 'gallery_story',
  CUSTOM_GALLERY_01: 'custom_gallery_01',
}

export const LOCALE = {
  VI: 'vi',
  EN: 'en',
}

export enum OrderStatus {
  PENDING = 'pending',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum ETemplates {
  Home = 'home',
  About = 'about',
  Blog = 'blog',
  CollectionPage = 'collection-page',
  AuthorPage = 'author-page',
}

export const PRODUCTS_PER_PAGE = 9 as const

export enum EPageTableNames {
  HOME_VN = 'home_page_vi',
  HOME_EN = 'home_page_en',
  BLOG_VN = 'blog_page_vi',
}

export enum EThemes {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum EHeaderStyles {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
}

export enum EPostType {
  TOURS = 'Tours',
  WORKS = 'Works',
  BLOG = 'Blog',
  SERIES = 'Series',
  GALLERY = 'Gallery',
  JOURNEY = 'Journey',
  COLLECTIONS = 'Collections',
  PRODUCT = 'Product',
}
export enum EProductType {
  TOURS = 'tour',
  BIKE = 'bike',
}

export enum EPageId {
  HOME_PAGE = '2',
  ABOUT_US = '3',
  TERMS_AND_CONDITIONS = '8',
  BIKES_RENTAL = '21',
  CONTACTS = '5',
  FAQS = '11',
  FFI_POLICY = '14',
  PRIVACY_POLICY = '15',
  TOURS = '17',
  BLOG = '19',
  GALLERY = '23',
  COLLECTIONS = '25',
  PRODUCTS = '27',
  CATEGORIES = '35',
}

export enum EContentMode {
  TIPTAP = 'tiptap',
  GRAPEJS = 'grapejs',
}

/**
 * This type use to define slug
 * eg: seid -> series id, pid -> detail id, gaid -> gallery id
 *
 */
export enum EPostIdCode {
  PRODUCT = 'prid',
  BIKE = 'bkid',
  TOUR = 'tid',
  COLLECTIONS = 'cid',
  CATEGORIES = 'caid',
  BLOG = 'bid',
  POST = 'pid',
  SERIES = 'seid',
  GALLERY = 'gaid',
  JOURNEY = 'joid',
  WORKS = 'workid',
  TAGS = 'tagid',
  PRODUCT_TAGS = 'ptagid',
}

/**
 * Form State
 * 0 -> Not submit
 * 1 -> submitting and waiting for the result
 * 2 -> submit success,
 * 3 -> submit error
 */
export enum EFormState {
  REST = 0,
  SUBMITTING = 1,
  SUCCESS = 2,
  ERROR = 3,
}

export const AVATAR = 'fit=cover&width=150&quality=85'
export const THUMBNAIL_SM = 'fit=cover&width=360&quality=85'
export const MARQUEE_GALLERY = 'fit=cover&width=350&quality=85'
export const THUMBNAIL_MD = 'fit=cover&width=750&quality=75'
export const THUMBNAIL_LG = 'fit=cover&width=2000&quality=95'
export const FOURK = 'fit=cover&width=4000&quality=95'
export const FULL = 'fit=cover&width=3000&quality=100'
export const GALLERY = 'fit=cover&width=500&quality=75'
export const HOME_JOURNEY_SLIDESHOW = 'fit=cover&width=1600&quality=85'

export const LEFT_META = [
  {
    slug: 'https://www.google.com/',
    content: 'content',
  },
  {
    slug: 'https://www.google.com/',
    content: 'content',
  },
  {
    slug: 'https://www.google.com/',
    content: 'content',
  },
]

export const LOCAL_STORAGE = {
  IS_OPEN_MODAL: 'isOpenModalLocalStorage',
  IS_REMIND_AFTER: 'isRemindAfter',
  MODAL_EXPIRE_AT: 'modalExpireAt',
}

export const PUBLIC_STATUS = 'published'
export const ACTIVE_STATUS = 'active'

export enum EComponentsName {
  Slider = 'slider',
  StaticImage = 'static-image',
  Announcement = 'announcement',
  Modal = 'modal',
}

export const DEFAULT_CURRENCY_NAME = 'USD'
export const DEFAULT_LIMIT_RESULT = 12

export enum GRAPEJS_CUSTOM_BLOCKS {
  HeroImageSearch = 'HeroImageSearch',
  ToggleCurrencies = 'ToggleCurrencies',
  ShopSideBar = 'ShopSideBar',
  ShopProducts = 'ShopProducts',
  LanguageSwitcher = 'LanguageSwitcher',
}

export enum PAGE_TYPE {
  ShopPage = 'shop',
  Error404 = 'error-404',
  HomePage = 'homepage',
  DetailPost = 'detail-post',
}
