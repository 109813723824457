import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { client } from '@/graphql/apollo-client'
import { RootStoreProvider } from 'src/providers/GlobalProvider'
import { AnimatePresence } from 'framer-motion'
import { RootStoreHydration } from 'src/stores/RootStore'

function CustomApp({
  Component,
  pageProps,
}: AppProps<{ hydrationData: RootStoreHydration; isSSR: boolean }>) {
  return (
    <RootStoreProvider
      hydrationData={{ ...pageProps.hydrationData }}
      isSSR={pageProps.isSSR}
    >
      <AnimatePresence mode="wait" initial={false}>
        <ApolloProvider client={client}>
          <Component {...pageProps} />
        </ApolloProvider>
      </AnimatePresence>
    </RootStoreProvider>
  )
}
export default CustomApp
